import axios from 'axios'

//  请求Blob
export function getBlob(path, suffix) {
  console.log(path)
  const url = encodeURI(path)
  axios.get(url, {
    responseType: 'blob'
  }).then(res => {
    console.log(res)
    if (res.status === 200) {
      const fileName = getFileName() + suffix
      downloadFile(res, fileName)
    }
  })
}

// 下载文件
function downloadFile(res, fileName) {
  const blob = new Blob([res.data]) //  res为从后台返回的数据
  if (!fileName) {
    fileName = res.headers['content-disposition'].split('filename=').pop()
  }
  if ('msSaveOrOpenBlob' in navigator) {
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)
    document.body.removeChild(elink)
  }
}

//  生成文件名
function getFileName() {
  const date = new Date()
  const y = date.getFullYear()
  let M = date.getMonth() + 1
  let d = date.getDate()
  let h = date.getHours()
  let m = date.getMinutes()
  let s = date.getSeconds()
  M = M > 9 ? M : '0' + M
  d = d > 9 ? d : '0' + d
  h = h > 9 ? h : '0' + h
  m = m > 9 ? m : '0' + m
  s = s > 9 ? s : '0' + s
  return '' + y + M + d + h + m + s
}
