<template>
  <div class="app-container">
    <div style="width: 1200px;">
      <img
        v-if="this.$language === 'cn'"
        style="width: 100%; height: auto;"
        src="../../assets/images/technical/kj_cn.png"
      >
      <img
        v-else
        style="width: 100%; height: auto;"
        src="../../assets/images/technical/kj_en.png"
      >
    </div>
    <div class="our_prent">
      <!-- 科技资讯 -->
      <span>{{ $t('m.navbar.technical_record') }}</span>
    </div>
    <div v-loading="loading" class="zjk_span">
      <div v-for="item in list" :key="item.id" class="kjda">
        <!-- -->
        <div style="float: left;">
          <!-- PDF图标 -->
          <div class="kjda_left">
            <img src="../../assets/images/technical/wj_05.png">
          </div>
          <!-- 文字 -->
          <div class="kjda_right">
            <span>
              {{ language === 'cn' ? item.name : item.englishName | formatTitle }}
            </span>
            <span class="kjda_dc">
              <!-- 文件大小 -->
              {{ $t('m.technical.file_size') }}：{{ item.size }}
              &nbsp;&nbsp;
              <!-- 上传时间 -->
              {{ $t('m.technical.update_time') }}：{{ item.publishTime }}
            </span>
          </div>
        </div>
        <!-- 资料下载 -->
        <div class="zlxz">
          <a
            @click="handleDownloadFile(item.fileName)"
          >
            {{ $t('m.technical.data_download') }}
          </a>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination-container" style="margin-top: 10px; padding-bottom: 0;">
      <el-pagination
        :page-size="listQuery.pageSize"
        :current-page.sync="listQuery.pageNum"
        :total="total"
        background
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
//
import { technicalList } from '@/api/cceec/technical'

import { getBlob } from '@/utils/file'

const defaultListQuery = {
  pageNum: 1,
  pageSize: 10
}
export default {
  name: 'Technical',
  filters: {
    formatTitle(val) {
      return val.replace(/@/g, '')
    }
  },
  data() {
    return {
      loading: false,
      listQuery: Object.assign({}, defaultListQuery),
      list: [],
      total: 0,
      language: this.$language
    }
  },
  created() {
    this.listQuery = Object.assign({
      languageType: this.$languageType
    }, this.listQuery)
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      technicalList(this.listQuery).then(res => {
        this.loading = false
        console.log(res)
        const list = res.data.list
        list.forEach(item => {
          item.download = process.env.VUE_APP_BASE_API + item.fileName
        })
        this.list = list
        this.total = res.data.total
      }).catch(() => {
        this.loading = false
      })
    },
    handleDownloadFile(path) {
      getBlob(process.env.VUE_APP_BASE_API + path, '.pdf')
    },
    //
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .zjk_span {
    margin: 0 auto;
    width: 1200px;
    min-height: 400px;
    .kjda {
      width: 1200px;
      height: 100px;
      border-bottom: 1px #ccc solid;
    }
  }
  .kjda_left {
    float: left;
    margin-top: 25px;
    img {
      vertical-align: middle;
    }
  }
  .kjda_right {
    float: left;
    padding-top: 5px;
    padding-left: 20px;
  }
  .zjk_span span:nth-child(1) {
    display: block;
    font-size: 15px;
    margin-top: 20px;
    line-height: 25px;
  }
  .kjda_dc {
    font-size: 14px;
  }
  .zlxz {
    float: right;
    margin-top: 48px;
    line-height: 30px;
    text-align: center;
    a {
      display: block;
      width: 70px;
      height: 30px;
      color: #7ea9d4;
      font-size: 12px;
      cursor: pointer;
      border-radius: 3px;
      text-decoration: none;
      border: 1px #3bb3e9 solid;
    }
    a:hover {
      color: #ffffff;
      background-color: #3bb3e9;
    }
  }
</style>
