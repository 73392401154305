import request from '@/utils/request'

//  科技资讯
export function technicalList(params) {
  return request({
    url: '/api/cceec/archiveList',
    method: 'get',
    params: params
  })
}
